<template>
  <div class="basic-container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="filterDataList()" @submit.native.prevent>
      <table class="detailTable_search" style="width: 100%">
        <tr>
          <td class="titleTd width80px">分组名称</td>
          <td class="valueTd">
            <el-input v-model="dataForm.name" placeholder="使用分组名称过滤列表，字母不区分大小写" clearable style="width:50%"></el-input>
          </td>
        </tr>
      </table>
    </el-form>
    <div class="wxts_msg_search" >
      <div class="f_t">
        <el-button-group>
          <el-button @click="filterDataList()" type="success" :loading="dataListLoading">过滤</el-button>
          <el-button @click="getDataList()" :loading="dataListLoading">刷新</el-button>
          <el-button v-if="isAuth('hbi:hbidc:save')" type="primary" @click="addOrUpdateHandle()">新增主分组</el-button>
        </el-button-group>
      </div>
    </div>
    <div class="data_tree" >
    <el-row :gutter="20">
      <el-col :span="12" :offset="6">
        <el-tree
          :data="dataTree"
          node-key="id"
          ref="dataTree"
          :filter-node-method="filterNode"
          default-expand-all
          :expand-on-click-node="false"
          :props="{label:'name', children:'children'}"
          v-loading="dataListLoading">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span>
              <el-button type="text" size="mini" @click="modifyNode(node, data)">修改</el-button>
              <el-button type="text" size="mini" @click="removeNode(data)">删除分组</el-button>
              <el-button type="text" size="mini" @click="addOrUpdateHandle(data)">追加子分组</el-button>
            </span>
          </span>
        </el-tree>
      </el-col>
    </el-row>
      <!-- <el-tree
        style="width:60%"
        :data="dataTree"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        :props="{label:'name', children:'children'}">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button type="text" size="mini" @click="modifyNode(data)">修改</el-button>
            <el-button type="text" size="mini" @click="removeNode(node, data)">删除分组</el-button>
            <el-button type="text" size="mini" @click="addOrUpdateHandle(data)">追加子分组</el-button>
          </span>
        </span>
      </el-tree> -->
    </div>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './add-or-update'
import { getAllList, deleteData } from '@/api/hbi/datacategory.js'
import { treeDataTranslate } from '@/utils/index.js'
export default {
  data () {
    return {
      dataForm: {
        name: '',
      },
      dataTree: [],
      dataListLoading: false,
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  activated () {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      getAllList().then(({ data }) => {
        if (data && data.code === 0) {
          const list = data.data
          this.dataTree = list && list.length > 0 ? treeDataTranslate(list, 'id', 'parentId') : [];
        } else {
          this.dataTree = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    /** 修改节点 */
    modifyNode(node, data){
      let pName = node.parent.data.name
      this.$set(data, 'parentName', pName);
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.modifyNode(data)
      })
    },
    /** 添加节点 */
    addOrUpdateHandle(data){
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.addNode(data)
      })
    },
    /** 删除节点 */
    removeNode(data){
      this.$confirm(`确定对分组【${data.name}】进行删除操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteData([data.id]).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message.success('操作成功')
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(() => { })
    },
    filterDataList(){
      this.$refs.dataTree.filter(this.dataForm.name);
    },
    filterNode(value, data) {
      if (!value) return true;
      let v = value.toLowerCase()
      let d = data.name.toLowerCase()
      return d.indexOf(v) !== -1;
    }
  }
}
</script>
<style scoped>
.data_tree span.custom-tree-node{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

</style>